<template>
  <div class="page">
     <top-bar :title="'居家养老'" :left="true"></top-bar>
    <div class="header">

      <van-tabs v-model="active" color="#0079fe" sticky>
        <van-tab title="基础信息"></van-tab>
        <van-tab title="既往病史"></van-tab>
        <van-tab title="家族病史"></van-tab>
        <van-tab title="其他信息"></van-tab>
      </van-tabs>
      <van-form @submit="onSubmit" input-align="right" error-message-align="right" @failed="onFailed">
        <div v-show="active==0">
          <van-field required v-model="formData.name" name="name" placeholder="请输入" label="姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
          <van-field required v-model="formData.idNumber" name="idNumber" label="身份证号" placeholder="请输入" :rules="[{ required: true, message: '请填写正确身份证号',pattern }]" />
          <van-field required readonly name="sex" :value="formData.sex" label="性別" @click="showPickerFun('sex')" placeholder="请选择" :rules="[{ required: true, message: '请选择',trigger:'onChange' }]" />
          <van-field required readonly name="birthday" :value="formData.birthday" label="出生年月" @click="showPickerFun('birthday')" placeholder="请选择" :rules="[{ required: true, message: '请选择出生年月',trigger:'onChange'}]" />
          <van-field required v-model="formData.mobile" name="mobile" label="手机号码" type="tel" placeholder="请输入" :rules="[{ required: true, message: '请填写手机号码' }]" />
          <van-field readonly v-model="formData.bloodType" name="bloodType" label="血型" @click="showPickerFun('bloodType')" placeholder="请选择" />
          <van-field readonly v-model="formData.education" name="education" label="文化程度" @click="showPickerFun('education')" placeholder="请选择" />
          <van-field readonly v-model="formData.marriage" name="marriage" label="婚姻状况" @click="showPickerFun('marriage')" placeholder="请选择" />
          <van-field required v-model="formData.relationName" name="relationName" label="家属姓名" placeholder="请输入" :rules="[{ required: true, message: '请填写家属姓名' }]" />
          <van-field required readonly v-model="formData.relationSelf" name="relationSelf" label="与本人关系" @click="showPickerFun('relationSelf')" placeholder="请选择" :rules="[{ required: true, message: '请选择与本人关系',trigger:'onChange' }]" />
          <van-field v-model="formData.relationPhone" name="relationPhone" label="家属联系方式" type="tel" placeholder="请输入" />
          <van-field readonly v-model="formData.paymentType" name="paymentType" label="医疗费用支付方式" @click="showPickerFun('paymentType')" placeholder="请选择" />
          <van-field v-model="formData.nowAddress" name="nowAddress" label="家庭住址" placeholder="请输入" />
          <van-field readonly v-model="formData.irritability" name="irritability" label="药物过敏史" @click="showPickerFun('irritability')" placeholder="请选择" />
        </div>
        <div v-show="active==1">
          <van-field name="illness" label="疾病">
            <template #input>
              <van-checkbox-group v-model="formData.illness" direction="horizontal">
                <van-checkbox style="width:48%;margin:0 0 4px 0" :name="item.value" shape="square" v-for="(item,index) in illnessTypeColumns" :key="index">{{item.text}}</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
          <van-field name="traumaStatus" label="外伤">
            <template #input>
              <van-radio-group v-model="formData.traumaStatus" direction="horizontal">
                <van-radio :name="0">无</van-radio>
                <van-radio :name="1">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="operationStatus" label="手术">
            <template #input>
              <van-radio-group v-model="formData.operationStatus" direction="horizontal">
                <van-radio :name="0">无</van-radio>
                <van-radio :name="1">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="transfusionStatus" label="输血">
            <template #input>
              <van-radio-group v-model="formData.transfusionStatus" direction="horizontal" >
                <van-radio :name="0" @click="transfusionStatusChange(0)">无</van-radio>
                <van-radio :name="1" @click="transfusionStatusChange(1)">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <template v-if="formData.transfusionStatus ==1">
            <div style="text-align: right;padding:10px 10px 0 0">
              <van-icon name="add" color="#1989fa" size="24" @click="addTransfusion" />
            </div>
            <div v-for="(i,indexC) in formData.statusVOList" :key="indexC" style="margin-top:10px">
              <van-field v-model="i.transfusionExtendName" name="transfusionExtendName" label="输血名称">
                <van-icon name="cross" slot="right-icon" color="red" v-if="indexC > 0" @click.prevent="deleteTransfusionRecordFun(indexC)" />
              </van-field>
              <van-field readonly name="transfusionExtendValue" :value="i.transfusionExtendValue" label="输血时间" @click="showPickerFun('transfusionExtendValue',indexC)" />
            </div>
          </template>
        </div>
        <div v-show="active==2">
          <van-field name="father" label="父亲">
            <template #input>
              <van-checkbox-group v-model="formData.father" direction="horizontal">
                <van-checkbox style="width:48%;margin:0 0 4px 0" :name="item.value" shape="square" v-for="(item,index) in illnessTypeColumns" :key="index">{{item.text}}</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
          <van-field name="mother" label="母亲">
            <template #input>
              <van-checkbox-group v-model="formData.mother" direction="horizontal">
                <van-checkbox style="width:48%;margin:0 0 4px 0" :name="item.value" shape="square" v-for="(item,index) in illnessTypeColumns" :key="index">{{item.text}}</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
          <van-field name="brother" label="兄弟姐妹">
            <template #input>
              <van-checkbox-group v-model="formData.brother" direction="horizontal">
                <van-checkbox style="width:48%;margin:0 0 4px 0" :name="item.value" shape="square" v-for="(item,index) in illnessTypeColumns" :key="index">{{item.text}}</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
          <van-field name="children" label="子女">
            <template #input>
              <van-checkbox-group v-model="formData.children" direction="horizontal">
                <van-checkbox style="width:48%;margin:0 0 4px 0" :name="item.value" shape="square" v-for="(item,index) in illnessTypeColumns" :key="index">{{item.text}}</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
        </div>
        <div v-show="active==3">
          <van-field name="inheritanceStatus" label="遗传病例">
            <template #input>
              <van-radio-group v-model="formData.inheritanceStatus" direction="horizontal">
                <van-radio :name="0">无</van-radio>
                <van-radio :name="1">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field readonly v-model="formData.disability" name="disability" label="残疾情况" @click="showPickerFun('disability')" />
          <van-field readonly name="healthTime" :value="formData.healthTime" label="体检时间" @click="showPickerFun('healthTime')" />
          <van-field v-model="formData.doctor" name="doctor" label="检查医生" />
          <van-field v-model="formData.userHeight" name="userHeight" label="身高(cm)" />
          <van-field v-model="formData.userWeight" name="userWeight" label="体重(Kg)" />
          <van-field v-model="formData.userWaistline" name="userWaistline" label="腰围(cm)" />
          <van-field v-model="formData.userTooth" name="userTooth" label="牙齿" />
          <van-field name="smoking" label="有无烟酒">
            <template #input>
              <van-radio-group v-model="formData.smoking" direction="horizontal">
                <van-radio name="0">无</van-radio>
                <van-radio name="1">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="exercise" label="有无运动">
            <template #input>
              <van-radio-group v-model="formData.exercise" direction="horizontal">
                <van-radio name="0">无</van-radio>
                <van-radio name="1">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="submit btns" >
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar :columns="sexColumns" v-if="type == 'sex'" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-datetime-picker show-toolbar type="date" v-if="type=='birthday'" title="选择年月日" :min-date="new Date(1900, 0, 1)" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-picker show-toolbar :columns="bloodTypeColumns" v-if="type == 'bloodType'" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-picker show-toolbar :columns="educationColumns" v-if="type == 'education'" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-picker show-toolbar :columns="marriageColumns" v-if="type == 'marriage'" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-picker show-toolbar :columns="relationSelfColumns" v-if="type == 'relationSelf'" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-picker show-toolbar :columns="paymentTypeColumns" v-if="type == 'paymentType'" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-picker show-toolbar :columns="irritabilityColumns" v-if="type == 'irritability'" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-picker show-toolbar :columns="disabilityColumns" v-if="type == 'disability'" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-datetime-picker show-toolbar type="date" v-if="type=='healthTime'" title="选择年月日" :currentDate="new Date()" @confirm="popupConfirm" @cancel="showPicker = false" />
        <van-datetime-picker show-toolbar type="date" v-if="type=='transfusionExtendValue'" title="选择年月日" :currentDate="new Date()" @confirm="popupConfirm" @cancel="showPicker = false" />
      </van-popup>
    </div>

  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { formatterDate, getTableCellStyle } from "@/utils/utils";
// import { upload, getDictTree } from '@/utils/common'
import { mapState } from "vuex";
import { Toast } from 'vant';
import { setTimeout } from 'timers';
export default {
  data() {
    return {
      pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      id: '',
      transfusionIndex: '',
      active: 0,
      type: "",
      showPicker: false,
      sexColumns: [{ text: "男", value: 1 }, { text: "女", value: 0 }],
      bloodTypeColumns: [
        { text: "不限", value: "" },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "AB", value: "AB" },
        { text: "O", value: "O" },
        { text: "RH阴性", value: "RH阴性" },
      ],
      disabilityColumns: [
        { text: "无", value: 0 },
        { text: "视力残疾", value: 1 },
        { text: "听力残疾", value: 2 },
        { text: "言语残疾", value: 3 },
        { text: "肢体残疾", value: 4 },
        { text: "智力残疾", value: 5 },
        { text: "精神残疾", value: 6 },
        { text: "其他残疾", value: 7 },
      ],
      relationSelfColumns: [
        { text: "其他", value: 0 },
        { text: "夫妻", value: 1 },
        { text: "儿子", value: 2 },
        { text: "女儿", value: 3 },
        { text: "儿媳", value: 4 },
        { text: "女婿", value: 5 },
        { text: "兄弟", value: 6 },
        { text: "侄子", value: 7 },
      ],
      paymentTypeColumns: [
        { text: "城镇职工基本医疗保险", value: 0 },
        { text: "城镇居民基本医疗保险", value: 1 },
        { text: "新型农村合作医疗保险", value: 2 },
        { text: "其他", value: 3 },
      ],
      irritabilityColumns: [
        { text: "无", value: 0 },
        { text: "青霉素", value: 1 },
        { text: "其他", value: 2 },
      ],
      educationColumns: [],
      marriageColumns: [],
      illnessTypeColumns: [],
      formData: {
        name: "",
        sex: '男',
        idNumber: "",
        birthday: "",
        mobile: "",
        bloodType: '不限',
        relationName: "",
        relationSelf: '',
        relationPhone: "",
        paymentType: '',
        education: '未知',
        marriage: '未知',
        nowAddress: "",
        irritability: '无',
        healthTime: "",
        doctor: "",
        userHeight: "",
        userWeight: "",
        userWaistline: "",
        userTooth: "",
        traumaStatus: 0,
        operationStatus: 0,
        transfusionStatus: 0,
        statusVOList: [
          { transfusionExtendName: "", transfusionExtendValue: "" },
        ],
        inheritanceStatus: 0,
        disability: '无',
        smoking: "0",
        exercise:"0",
        userId: this.$userId,
        illness: [],
        father: [],
        mother: [],
        brother: [],
        children: [],
      },
    };
  },
  created() {
    this.getDictList('education');
    this.getDictList('marriage');
    this.getDictList('illnessType');
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.$nextTick(() => {
        this.$http({
          url: this.$http.adornUrl(
            `/wxapp/app/appjqhomecare/info/${this.id}`
          ),
          method: "get",
          params: this.$http.adornParams(),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("data", data);
            for (let i in this.formData) {
              this.formData[i] = data.appJqHomeCare[i];
              this.formData.illness = data.appJqHomeCare.illness.split("|").map(Number);
              this.formData.father = data.appJqHomeCare.father.split("|").map(Number);
              this.formData.brother = data.appJqHomeCare.brother.split("|").map(Number);
              this.formData.mother = data.appJqHomeCare.mother.split("|").map(Number);
              this.formData.children = data.appJqHomeCare.children.split("|").map(Number);
            }
            this.formData.sex = this.getText("sex");
            this.formData.bloodType = this.getText('bloodType')
            this.formData.education = this.getText('education')
            this.formData.marriage = this.getText('marriage')
            this.formData.relationSelf = this.getText('relationSelf')
            this.formData.irritability = this.getText('irritability')
            this.formData.disability = this.getText('disability')
            this.formData.paymentType = this.getText('paymentType');
            console.log("illness", this.formData)
          } else {
            Toast.fail(data.msg)
          }
        });
      })
    } else {
      this.id = ''
    }
  },
  beforeDestroy() { },
  methods: {
    getDictList(code) {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
        method: 'get',
        params: this.$http.adornParams({
          'code': code,
          'orgId': this.$orgId
        })
      }).then(({ data }) => {
        // console.log("data.dicts", data)
        if (data && data.code === 0) {
          data.dicts.map(item => {
            this[code + 'Columns'].push({ text: item.label, value: item.value })
          })
        }
      })
    },
    onSubmit(values) {
      // console.log("submit", values);
      console.log(this.formData)
      values.sex = this.getValue('sex')
      values.bloodType = this.getValue('bloodType')
      values.education = this.getValue('education')
      values.marriage = this.getValue('marriage')
      values.relationSelf = this.getValue('relationSelf')
      values.irritability = this.getValue('irritability')
      values.disability = this.getValue('disability')
      values.paymentType = this.getValue('paymentType')
      values.illness = values.illness.length > 0 ? values.illness.join("|") : ''
      values.father = values.father.length > 0 ? values.father.join("|") : ''
      values.mother = values.mother.length > 0 ? values.mother.join("|") : ''
      values.brother = values.brother.length > 0 ? values.brother.join("|") : ''
      values.children = values.children.length > 0 ? values.children.join("|") : ''
      values.statusVOList = this.formData.statusVOList
      console.log("a", values)
      if (this.formData.transfusionStatus == 1) {
        let n = this.formData.statusVOList.some(
          (item) =>
            item.transfusionExtendName == "" ||
            item.transfusionExtendName == null ||
            item.transfusionExtendName == undefined
        );
        let m = this.formData.statusVOList.some(
          (item) =>
            item.transfusionExtendValue == "" ||
            item.transfusionExtendValue == null ||
            item.transfusionExtendValue == undefined
        );
        if (n) {
          this.active = 1;
          Toast.fail("输血名称不能空")
          return;
        }
        if (m) {
          this.active = 1;
          Toast.fail("输血时间不能空")
          return;
        }
      }
      this.$http({
        url: this.$http.adornUrl(`/wxapp/app/appjqhomecare/save`),
        method: "post",
        data: this.$http.adornData({
          id: this.id,
          ...values
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          Toast.success('操作成功');
          setTimeout(() => {
            this.$router.push({ name: 'live-old' })
          }, 1000)
        } else {
          Toast.fail(data.msg)
        }
      });
    },
    onFailed(value) {
      console.log("value", value)
      if (value.errors.length > 0) this.active = 0
    },
    addTransfusion() {
      this.formData.statusVOList.push({
        transfusionExtendName: "",
        transfusionExtendValue: "",
      });
    },
    transfusionStatusChange(value) {
      console.log("value", value)
      if (!this.id) {
        this.formData.statusVOList = [
          { transfusionExtendName: "", transfusionExtendValue: "" },
        ];
      }

    },
    deleteTransfusionRecordFun(index) {
      this.formData.statusVOList.splice(index, 1);
    },
    popupConfirm(e) {
      console.log("e", e)
      if (this.type == 'birthday' || this.type == 'healthTime') {
        this.formData[this.type] = formatterDate(e)
      } else if (this.type == 'transfusionExtendValue') {
        this.formData.statusVOList[this.transfusionIndex]['transfusionExtendValue'] = formatterDate(e)
      } else {
        this.formData[this.type] = e.text;
      }
      this.showPicker = false;
    },
    getValue(key) {
      let filter = ''
      filter = this[key + 'Columns'].filter(item => { return (item.text == this.formData[key]) })
      return filter.length <= 0 ? '' : filter[0].value
    },
    getText(key) {
      let filter = ''
      filter = this[key + 'Columns'].filter(item => { return (item.value == this.formData[key]) })
      return filter.length <= 0 ? '' : filter[0].text
    },
    showPickerFun(type, index) {
      console.log(type);
      this.transfusionIndex = index
      this.type = type;
      this.showPicker = true;
    },
  },

  components: {
    topBar,
  },
};
</script>
<style lang="scss" scoped>
.page {
  padding-bottom: 150px;
  min-height: 100vh;
  background: #f5f5f5;
  box-sizing: border-box;
  .header {
    border-top: 1px solid #eee;
  }
}
</style>
